import React from "react";
import { createGlobalStyle } from "styled-components";
import SegoeUI_Woff from "../assets/fonts/segoeuib.woff";
import SegoeUI_Woff2 from "../assets/fonts/segoeuib.woff2";

const GlobalStyle = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800;900&family=Noto+Serif&display=swap');


  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    body{
      font-family: "Segoe UI", Arial;
      color: #2F2B2B;
    }

    @font-face {
        font-family: 'Segoe';
        src: local('Segoe'), local('Segoe'),
        url(${SegoeUI_Woff}) format('woff'),
        url(${SegoeUI_Woff2}) format('woff');
        font-weight: 700;
        font-style: normal;
    }

    a,
    a:hover,
    a:active,
    a:visited {
      text-decoration: none;
      color: #2F2B2B;
      margin: 0;
}
   
    button{
      border: 0;
      cursor: pointer;
    }

    
`;

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
};

export default Layout;
