import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

export const MainButton = ({ text, live, link, outline, isOpen, setIsOpen }) => {
  return (
    <S.MainButton outline={outline}>
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <button>{text && <span className="hero-btn-text">{text}</span>}</button>
        </a>
      )}

      {!link && <button onClick={() => setIsOpen(!isOpen)}>{text && <span className="hero-btn-text">{text}</span>}</button>}
    </S.MainButton>
  );
};

const S = {};

S.MainButton = styled.div`
  button {
    border: 1px solid #0089ff;
    border-radius: 10px;
    /* background-color: ${(props) => (props.type === "main" ? "#0089ff" : "#fff")}; */
    height: 40px;
    padding: 0 23px;
    display: inline-block;
    /* color: ${(props) => (props.type === "main" ? "#fff" : "#0089ff")}; */
    background-color: #0089ff;
    color: #fff;

    ${(props) => {
      if (props.outline == true)
        return `
            background-color: #fff;
            color: #0089FF;
        `;
    }}
  }

  button:hover {
    border-color: #7bbbf3;
    background-color: #7bbbf3;
    color: #fff;
  }

  .hero-btn-text {
    font-family: "Montserrat";
    font-weight: 800;
    white-space: nowrap;
  }
`;
