import slack_banner from "./assets/images/slack_banner.png";
import ih_banner from "./assets/images/ih_banner.png";
import amazon_banner from "./assets/images/amazon_banner.png";
import github_icon from "./assets/images/github_icon.svg";
import linkedin_icon from "./assets/images/linkedin.svg";
import dribble_icon from "./assets/images/dribble.svg";

//Tt

export const portfolioItems = [
  {
    title: "Amazon Clone",
    github_link: "https://github.com/adms01/amazon-frontend-clone",
    subtitle: "Full stack Javascript build with React, Node, Typescript, Express and PostgreSQL.",
    img: amazon_banner,
    link: "https://notrealamazon.com",
    summary:
      "This a full stack Javascript build which emulates the Amazon experience of browsing products, adding products to basket and ordering items.",
    features: [
      "Create account, Login, Logout and Reset password",
      "Browse items",
      "Add and remove items from basket",
      "Persist basket",
      "Checkout with stripe",
      "Mobile friendly",
    ],
    frontend: ["React.js", "Redux", "React-query", "Styled-components", "Formik and Yup"],
    backend: [
      "Express Rest API",
      "Typescript",
      "Node.js",
      "PostgreSQL",
      "Knex/Objection ORM",
      "Yup Validation",
      "Sendgrid API",
      "Stripe API",
    ],
  },
  {
    title: "Slack Clone",
    github_link: "https://github.com/adms01/slack-clone",
    subtitle: "Frontend Javascript build with React and Firebase",
    img: slack_banner,
    link: "https://slack-clone-a7961.web.app/",
    summary: "A frontend focused build, enabling users to send and receive messages in channels.",
    features: [
      "Oauth login with Google",
      "Create channels",
      "Choose a channel to have a conversation in",
      "View, Send and receive messages",
    ],
    frontend: ["React.js", "Redux", "Material-UI", "Styled-components"],
    backend: ["Firebase", "Firestore (NoSQL)"],
  },
  {
    title: "InsideHero Landing page",
    subtitle: "Rebuilt company's website using React from scratch. Increased lead generation by 13%.",
    link: "https://insidehero.io",
    img: ih_banner,
    summary: "Rebuilt company's website using React from scratch. Increased lead generation by 13%.",
    features: ["Lazy loading", "Responsive", "Integrated with GetForm API"],
    frontend: ["Gatsby.js (based on React)", "SCSS", "S3 Bucket", "Formik and Yup"],
  },
];

export const menuItems = [
  // {
  //   img: linkedin_icon,
  //   link: "https://linkedin.com/in/danielcodes",
  // },
  {
    img: dribble_icon,
    link: "https://dribbble.com/daniel_adams",
  },
  {
    img: github_icon,
    title: "GITHUB",
    link: "https://github.com/adms01",
  },
];
