import * as React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { HeroButton } from "../components/HeroButton";
import profile_img from "../assets/images/profile_img.svg";
import ProjectItem from "../components/ProjectItem";
import Footer from "../components/Footer";
import { device } from "../assets/styles/Breakpoints";
import { portfolioItems, menuItems } from "../data";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Daniel Adam's Portfolio</title>
      </Helmet>

      <S.IndexPage>
        <div className="container">
          <section className="hero-section">
            {/* <div className="image-container">
              <img src={profile_img} alt="Portrait" />
            </div> */}

            <div className="hero-text">
              <h1>
                Hi, I’m Daniel - <span>Full stack developer</span> & designer based in Manchester.
              </h1>

              <div className="hero-actions">
                {menuItems?.map((x, i) => (
                  <HeroButton key={i} img={x.img} title={x.title} link={x.link} />
                ))}
              </div>
            </div>
          </section>

          <section className="projects-section">
            {portfolioItems?.map((x, i) => (
              <ProjectItem
                key={i}
                title={x.title}
                github_link={x.github_link}
                subtitle={x.subtitle}
                img={x.img}
                link={x.link}
                summary={x.summary}
                features={x.features}
                frontend={x.frontend}
                backend={x.backend}
              />
            ))}
          </section>
        </div>

        <Footer />
      </S.IndexPage>
    </Layout>
  );
};

export default IndexPage;

const S = {};

S.IndexPage = styled.div`
  .container {
    max-width: 1105px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .hero-section {
    display: flex;
    margin-top: 204px;
    margin-bottom: 130px;
  }

  .hero-text {
    display: flex;
    flex-direction: column;

    h1 {
      font-family: "Segoe", "Segoe UI";
      font-size: 40px;
      line-height: 53px;
      max-width: 85%;
    }

    h1 span {
      color: #0089ff;
    }
  }

  .hero-actions {
    display: flex;
    margin-top: 35px;
  }

  .image-container {
    max-width: 180px;
    width: 100%;
    height: auto;
    margin-right: 30px;
  }

  .projects-section .project-item:first-child {
    /* background-color: red; */
    margin-top: 0;
  }

  @media ${device.tablet} {
    .hero-section {
      flex-direction: column;
      margin-top: 10vh;
      margin-bottom: 15vh;
    }

    .hero-text {
      margin-top: 10px;
      h1 {
        font-size: 28px;
        line-height: normal;
      }
    }

    .hero-actions {
      flex-wrap: wrap;
      margin-top: 20px;
    }
  }
`;
