import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <S.Footer>
      <div className="footer-container">
        {/* <div className="column">
          <ul>
            <li>
              <Link to="/l">About</Link>
            </li> 

            <li>
              <Link to="/l">Contact</Link>
            </li>
          </ul>
        </div> */}

        <div className="column">
          <ul>
            {/* <li>
              <a href="https://linkedin.com/in/danielcodes" target="_blank">
                LinkedIn
              </a>
            </li> */}
            <li>
              <a href="https://github.com/adms01" target="_blank">
                GitHub
              </a>
            </li>
            <li>
              <a href="https://app.codesignal.com/profile/adms01" target="_blank">
                Codesignal
              </a>
            </li>
            <li>
              <a href="https://dribbble.com/daniel_adams" target="_blank">
                Dribble
              </a>
            </li>
          </ul>
        </div>
      </div>
    </S.Footer>
  );
};

export default Footer;

const S = {};

S.Footer = styled.div`
  margin-top: 200px;
  border-top: 1px solid #d9d9d9;

  .footer-container {
    max-width: 1085px;
    margin: 0 auto;
    display: flex;
    padding-top: 90px;
    padding-bottom: 96px;
  }

  .column {
    margin: 0 50px;
    padding-left: 20px;
  }

  .column:first-child {
    margin-left: 0;
  }

  ul {
    text-decoration: none;
    list-style: none;
  }

  li {
    padding: 7.5px 0;
  }
`;
