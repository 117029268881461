import React, { useState, useEffect } from "react";
import styled from "styled-components";
import github_icon from "../assets/images/github_icon.svg";
import { MainButton } from "./MainButton";
import { device } from "../assets/styles/Breakpoints";
import Popup from "./Popup";
const ProjectItem = ({ title, github_link, subtitle, img, link, summary, features, frontend, backend }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.ProjectItem className="project-item">
      <Popup
        isOpen={isOpen}
        setOpen={setIsOpen}
        summary={summary}
        features={features}
        frontend={frontend}
        backend={backend}
        link={link}
        github_link={github_link}
        title={title}
      />

      <div className="project-content">
        <div className="left">
          <div className="title-container">
            <div className="title">{title}</div>

            {github_link && (
              <a href={github_link}>
                <img src={github_icon} alt="" />
              </a>
            )}
          </div>

          <div className="subtitle">{subtitle}</div>
        </div>

        <div className="right">
          {link && (
            <div className="btn-demo">
              <MainButton text="See Live Version" link={link} />
            </div>
          )}

          <MainButton text="Learn More" outline={true} isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>

      {/* <ReactPlayer
        url="https://vimeo.com/572080731"
        width={"1085px"}
        height={"542px"}
        controls={true}
        light={true}
        playing={true}
        config={{
          vimeo: {
            playerOptions: {
              height: "542",
              width: "1085",
              byline: false,
              title: false,
            },
          },
        }}
      /> */}

      {/* <ReactPlayer
        url="https://dan-adms864.wistia.com/medias/s3zd72kecz"
        width={"1085px"}
        height={"542px"}
        controls={true}
        light={amazon_placeholder}
        config={{
          wistia: {
            options: {
              controlsVisibleOnLoad: false,
            },
          },
        }}
      /> */}

      <img src={img} alt={title} />
    </S.ProjectItem>
  );
};

export default ProjectItem;

const S = {};

S.ProjectItem = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 60px;
  /* margin-top: 50px;

  &:first-of-type {
    margin-top: 0;
  } */

  img {
    max-width: 1085px;
    height: auto;
    width: 100%;
  }

  .project-content {
    display: flex;
    margin-bottom: 34px;
    align-items: center;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
  }

  .title {
    font-family: "Montserrat", "Arial";
    font-size: 20px;
    font-weight: 800;
    margin-right: 17px;
  }

  .title-container img {
    width: 19px;
    height: auto;
    cursor: pointer;
  }

  .subtitle {
    font-family: "Noto Serif";
    margin-top: 7px;
    font-size: 16px;
  }

  .left {
    max-width: 50%;
  }

  .right {
    display: flex;
  }

  .btn-demo {
    margin-right: 16px;
  }

  @media ${device.tablet} {
    .project-content {
      flex-direction: column;
      align-items: flex-start;
    }

    .left {
      max-width: 100%;
    }

    .right {
      margin-top: 20px;
      flex-wrap: wrap;
    }
  }

  @media ${device.mobileM} {
    .btn-demo {
      margin-bottom: 10px;
    }
  }
`;
