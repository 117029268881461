import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { device } from "../assets/styles/Breakpoints";

export const HeroButton = ({ img, title, link }) => {
  return (
    <S.HeroButton spaceimgtitle={!!img && !!title}>
      <a href={link} target="_blank">
        <button>
          {img && <img src={img} />}
          {title && <span className="hero-btn-text">{title}</span>}
        </button>
      </a>
    </S.HeroButton>
  );
};

const S = {};

S.HeroButton = styled.div`
  margin-right: 12px;

  button {
    border: 1px solid #d9d9d9;
    background-color: #fff;
    height: 32px;
    padding: 0 10px;
    display: inline-block;
    display: flex;
    align-items: center;
  }

  button:hover {
    border-color: #d9d9d9;
    background-color: #d9d9d9;
    color: #fff;
  }

  .hero-btn-text {
    font-family: "Montserrat";
    font-weight: 800;
    white-space: nowrap;
  }

  img {
    width: 16px;
    height: 16px;

    ${(props) => {
      if (props.spaceimgtitle === true)
        return `
            margin-right: 5px;
        `;
    }}
  }

  @media ${device.tablet} {
    margin-top: 12px;
  }
`;
